@use 'contrastMixin';

.contrast {
  color: white;
}


.main-banner {
  margin: 0.0rem;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  position: relative;
  padding: 2rem;
  overflow: hidden;
  text-align: left;
  display: flex;
  align-items: left;
  justify-content: left;
  background-color: #171a1f;
  max-width: auto;
  width: 100%;
  height: 100%;
  max-height: auto;
  content: "";
  background-image: url("/assets/power/vm_photo.png");
  background-size: cover;
  background-position: center center;
}

.main-banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.main-banner:hover::before {
  opacity: 1;
}

.main-banner-text {
  font-size: 1.2rem;
  font-weight: 200;
  position: relative;
  color: white;
  min-width: 60%;
}

.btn-banner {
  color: white;
  border-color: white;
}

.btn-banner:hover {
  color: #1aab9a;
  border-color: #1aab9a;
}

.lead p {
  font-weight: 400;
  font-style: italic;
}

.img-logotype-POWER {
  padding: 1px;
  margin: 0.0rem;
  max-width: 100%;
  height: auto;
  // max-height: 95vh;
  content: ""; //url("/assets/power/vm_logo.png");
  background-image: url("/assets/power/vm_photo.png");

}

.contrast-icon {
  margin: 0.2rem;
  border: 0px solid;
  border-radius: .3rem;
}

.contrast-default,
svg.contrast-default path {
  color: #61dafb !important;
  background-color: white !important;
}

.contrast-white-black,
svg.contrast-white-black path {
  color: white !important;
  background-color: black !important;
}

.contrast-black-yellow,
svg.contrast-black-yellow path {
  color: black !important;
  background-color: yellow !important;
}

.contrast-yellow-black,
svg.contrast-yellow-black path {
  color: Yellow !important;
  background-color: Black !important;
}


.language-select .pok-react-select__control {
  background-color: #212529;
  border: none;
  box-shadow: none;
}

.language-select .pok-react-select__indicator-separator {
  display: none;
}

.language-select .pok-react-select__dropdown-indicator {
  display: none;
}

.language-select .pok-react-select__value-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  padding: 0;
}

.language-select .pok-react-select__single-value {
  display: flex;
  align-items: center;
  justify-content: center;
}

.language-select .pok-react-select__single-value img {
  max-width: 100%;
  height: auto;
  display: block;
}

.language-select .pok-react-select__option {
  display: flex;
  align-items: center;
  padding: 10px;
  height: 40px;
}

.language-select .pok-react-select__option img {
  max-width: 20px;
  height: auto;
  margin-right: 10px;
  display: block;
}