.pdf-viewer {
  border: #484343;
  border-style: solid;
  border-width: 1px;
  padding: 10px;
  border-radius: 5px;
  margin-left: 20px;
  flex: 1;
}

.pdf-viewer__scroll_x {
  overflow-x: auto;
  max-width: 100%;
}

.pdf-viewer__scroll_y {
  overflow-y: auto;
  max-height: 120vh;
  flex-grow: 1;
  min-height: 0;
}

.pdf-viewer__controls {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.pdf-viewer__controls-left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.pdf-viewer__controls-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.pdf-viewer__scale-display {
  width: 50px;
  text-align: center;
}

.react-pdf__Page__textContent  {
  color: transparent;
  opacity: 0.5;
}

.react-pdf__Page__textContent ::selection {
  background: #7a7af1;
}

.pdf-viewer__signature {
  cursor: url('../../../assets/pointer.png') 25 25, auto;
}

.pdf-viewer__scroll_x.grab {
  cursor: grab;
}

.pdf-viewer__scroll_x.grabbing {
  cursor: grabbing !important;
}